<template>
  <div class="shelvesPage">
    <BaseProductPage
      :cols="cols"
      :resetUrl="url"
      :formField="fields"
      :isEdit="true"
      :isSearch="isSearch"
      :selection="false"
      :expandTableOption="historyOption"
      :spanMethod="spanMethod"
      :rowClassName="rowClassName"
    >
      <template #batchOperation="{ sup_this }">
        <BatchOperation class="batch-operation-wrapper" style="margin-top: 0px">
          <div class="g-left-operation">
            <el-button
              v-if="permission.create"
              type="primary"
              size="small"
              class="icon-add"
              @click="addGearsCategory(sup_this)"
              >新增分类</el-button
            >
          </div>
        </BatchOperation>
      </template>
      <template v-slot="{ sup_this }">
        <el-table-column align="center" label="操作" width="300px">
          <template slot-scope="{ row }">
            <el-button
              v-if="permission.manage"
              type="text"
              class="menu-btn-item"
              @click="gearsManage(row)"
              >管理档位</el-button
            >
            <el-button
              v-if="permission.edit"
              type="text"
              class="menu-btn-item"
              @click="editGears(row, sup_this)"
              >编辑</el-button
            >
            <statusBtn
              v-if="permission.disable"
              :data="row"
              statusProp="enabled"
              type="gearsEnabledDelete"
              :afterSubmit="sup_this.init"
              v-bind="GEARS_ENABLED_OBJ"
            ></statusBtn>
            <statusBtn
              v-if="permission.delete && row.modifyType !== MODIFY_TYPE_OBJ.activeValue"
              :data="row"
              statusProp="modifyType"
              type="batchDeleteGears"
              activeText="删除"
              activeType="danger"
              :afterSubmit="afterBatchDeleteGears(sup_this)"
            ></statusBtn>
          </template>
        </el-table-column>
      </template>
    </BaseProductPage>
    <BaseDialog
      v-if="permission.create"
      style="text-align: left"
      @closeHandle="handleClosed"
      :dialogVisible.sync="showDialog"
      :append-to-body="false"
      :modal-append-to-body="false"
      width="600px"
      title="添加分类"
    >
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="95px" class="demo-ruleForm">
        <el-form-item label="分类名称：" prop="name">
          <el-input v-model="ruleForm.name" placeholder="请输入分类名称" size="small"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button type="primary" @click="handleConfirmed" :loading="addLoading"> 确认 </el-button>
        <LoadingBtn @click="handleCanceled"> 取消 </LoadingBtn>
      </template>
    </BaseDialog>
    <BaseDialog
      v-if="permission.edit"
      style="text-align: left"
      @closeHandle="handleDialogClosed"
      :dialogVisible.sync="showEditDialog"
      :append-to-body="false"
      :modal-append-to-body="false"
      width="600px"
      title="编辑分类"
    >
      <el-form label-width="95px" class="demo-ruleForm">
        <el-form-item label="分类名称：" prop="name">
          <el-input v-model="gearName" placeholder="请输入分类名称" size="small"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <LoadingBtn
          type="primary"
          @click="handleEditConfirmed"
          :disabled="gearName.trim() === ''"
          :loading="editLoading"
        >
          确认
        </LoadingBtn>
        <LoadingBtn @click="handleEditCanceled"> 取消 </LoadingBtn>
      </template>
    </BaseDialog>
  </div>
</template>

<script>
import { addGearsCategory, updateGearsCategoryList, getGearsRecordList as getList } from '@/api/product/productApi'
import { gearsCategoryCols as cols } from '../cols'
import BaseProductPage from '../baseProductPage'
import statusBtn from '@/views/components/statusBtn'
import customTable from '@/views/components/customTable'
import { GEARS_ENABLED_OBJ, MODIFY_TYPE_OBJ } from '@/utils/constant'
import { checkPermission } from '@/utils'

let permission = {
  create: checkPermission('externaladmin:product:editGears:create'),
  manage: checkPermission('externaladmin:product:editGears:manage'),
  edit: checkPermission('externaladmin:product:editGears:edit'),
  disable: checkPermission('externaladmin:product:editGears:disable'),
  delete: checkPermission('externaladmin:product:editGears:delete')
}
let historyOption = {
  getList,
  getPostData(row) {
    return {
      modifyType: 1,
      levelCategoryId: row.parentId,
      orderItems: [
        {
          asc: false,
          column: 'modify_time'
        }
      ]
    }
  },
  menuText: '查看档位',
  editBtn: false,
  delBtn: false,
  topPage: false,
  column: [
    {
      label: '编辑时间',
      prop: 'modifyTime'
    },
    {
      label: '档位分类',
      prop: 'levelCategoryId',
      type: 'select',
      dicType: 'gearsCategory'
    },
    {
      label: '档位数',
      prop: 'levelCategoryCount',
      formatter(row, column, value) {
        return `${value}个档位`
      }
    }
  ]
}
let viewGearsOption = {
  topPage: false,
  page: false,
  menu: false,
  column: [
    {
      label: '档位',
      prop: 'name'
    },
    {
      label: '数据',
      prop: 'num'
    }
  ]
}

export default {
  name: 'gearsManage',
  components: {
    BaseProductPage,
    statusBtn,
    customTable
  },
  data() {
    return {
      permission: Object.freeze(permission),
      MODIFY_TYPE_OBJ: Object.freeze(MODIFY_TYPE_OBJ),
      GEARS_ENABLED_OBJ: Object.freeze(GEARS_ENABLED_OBJ),
      isSearch: this.checkPermission(['externaladmin:product:editGears:search']),
      cols,
      editLoading: false,
      addLoading: false,
      sup_this: null,
      sup_that: null,
      gearName: '',
      fields: [
        {
          label: '档位分类',
          prop: 'name',
          name: 'name',
          placeholder: '请输入档位分类名称',
          colNum: 6
        },
        {
          label: '',
          prop: 'done',
          name: 'done',
          colNum: 1,
          slotName: 'doneSlot'
        }
      ],
      url: '/externaladmin/productService/levelCategory/list',
      categoryName: '',
      originalName: '',
      gear: {},
      method: 'post',
      mergeData: {
        name: ''
      },
      showDialog: false,
      showEditDialog: false,
      ruleForm: {
        name: ''
      },
      rules: {
        name: [{ required: true, message: '请输入分类名称', trigger: 'blur' }]
      }
    }
  },
  computed: {
    historyOption() {
      this.$store.dispatch('HandleOption', historyOption)
      historyOption.menuFn = this.viewGears
      return historyOption
    }
  },
  methods: {
    gearsManage(row) {
      this.$router.push(`/product/gearsManage/${row.id}?enabled=${row.enabled}`)
    },
    editGears(gear, sup_this) {
      this.sup_that = sup_this
      this.gear = gear
      this.gearName = gear.name
      this.originalName = gear.name
      this.showEditDialog = true
    },
    addGearsCategory(sup_this) {
      this.sup_this = sup_this
      this.showDialog = true
    },
    handleClosed() {},
    handleDialogClosed() {},
    async handleEditConfirmed() {
      this.editLoading = true
      //如果没有修改名称，则不请求接口
      if (this.gearName === this.originalName) {
        this.showEditDialog = false
      } else {
        const data = {
          id: this.gear.id,
          name: this.gearName
        }
        await updateGearsCategoryList(data).then((res) => {
          if (!res) return
          this.sup_that.init()
          this.showEditDialog = false
          this.$message({
            type: 'success',
            message: '修改档位分类名称成功!'
          })
        })
      }
      this.editLoading = false
    },
    handleEditCanceled() {
      this.showEditDialog = false
    },
    handleConfirmed() {
      this.addLoading = true
      this.$refs.ruleForm.validate(async (valid) => {
        if (!valid) {
          this.addLoading = false
          return
        }
        const data = {
          name: this.ruleForm.name
        }
        await addGearsCategory(data)
          .then((res) => {
            if (!res) {
              this.addLoading = false
              return
            }
            this.sup_this.init()
            this.$message({
              type: 'success',
              message: '成功新增档位分类!'
            })
            this.addLoading = false
            this.ruleForm.name = ''
            this.showDialog = false
          })
          .catch(() => {
            this.addLoading = false
          })
      })
    },
    handleCanceled() {
      this.ruleForm.name = ''
      this.showDialog = false
    },

    afterBatchDeleteGears(sup_this) {
      return () => {
        this.$store.dispatch('RefreshDic', 'gearsCategory')
        sup_this?.init()
      }
    },

    spanMethod({ row, columnIndex }) {
      if (row.$expandLevel === 2) {
        if (columnIndex === 0) {
          return [1, 5]
        } else {
          return [0, 0]
        }
      }
      return [1, 1]
    },
    rowClassName({ row: { $expandLevel } }) {
      if ($expandLevel === 2) return 'bg-transparent'
    },
    async viewGears(row) {
      let data = row.levelCategoryDetail
      if (!data) return this.$message.warning('无档位详情')
      data = JSON.parse(data).map((item) => {
        return {
          name: `${item.level}档`,
          num: item.maxCount >= 9999 ? `${item.minCount}+` : `${item.minCount}-${item.maxCount}件`
        }
      })
      console.log(data)
      this.$dialogTable({
        title: '查看档位',
        data,
        option: viewGearsOption,
        componentAttrs: {
          class: 'cell-pl78'
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
