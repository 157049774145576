<template>
  <Popover class="line menu-btn-item" :placement="$attrs.placement" @sureHandler="handleDisabled">
    <template #tip>
      <slot name="tip">
        <p>确定{{ disabledBtnStatus.text }}？</p>
      </slot>
    </template>
    <template #reference="{ scope: loading }">
      <color-text-btn
        :type="disabledBtnStatus.type"
        :size="size"
        class="filter-item"
        :loading="loading"
      >
        {{ disabledBtnStatus.text }}
      </color-text-btn>
    </template>
  </Popover>
</template>
<script>
import request from '@/service/request'
import { getXMenuType } from '@/utils/constant'

let urls = {
  user: '/externaladmin/authService/user/isDisabled',
  updateReferSize: '/externaladmin/productService/referSize/updateReferSize',
  gearsEnabledDelete: '/externaladmin/productService/levelCategory/Enabled',
  batchDeleteGears: '/externaladmin/productService/levelCategory/deleteCategory',
  publicProductBatchDelete: '/externaladmin/productService/finishedProd/deleted',
  attributeEnabled: '/externaladmin/productService/levelCategory/Enabled',
  templateDisabled: '/externaladmin/productService/productTemplate/updateTemplate',
  attributeDisable: '/externaladmin/orderService/exportFieldManagement/update'
}
export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    statusProp: {
      type: String,
      default: 'isDisabled'
    },
    expandParmas:Object,
    disabledValue: {
      type: Boolean|Number,
      default: 0
    },
    disabledText: {
      type: String,
      default: '禁用'
    },
    disabledType: {
      type: String,
      default: 'danger'
    },
    activeValue: {
      type: Boolean|Number,
      default: 1
    },
    activeText: {
      type: String,
      default: '启用'
    },
    activeType: {
      type: String,
      default: 'text'
    },
    size: {
      type: String,
      default: 'large'
    },
    afterSubmit: Function,
    type: {
      type: String,
      default: 'user'
    },
    api: {
      type: Function
    },
    postData: Object,
    method: {
      type: String,
      default: 'post'
    }
  },
  data() {
    return {

    }
  },
  computed: {
    value({ data, statusProp }) {
      return data[statusProp]
    },
    isDisabled({ value, disabledValue }) {
      return value === disabledValue
    },
    isDeleteApi({ type }) {
      return /delete/i.test(type)
    },
    isBatchApi({ type }) {
      return /batch/i.test(type)
    },
    // 改变后的值
    changedValue({ isDisabled, disabledValue, activeValue }) {
      return isDisabled ? activeValue : disabledValue
    },
    disabledBtnStatus({ isDisabled, disabledText, disabledType, activeText, activeType }) {
      if (isDisabled) return {
        text: activeText,
        type: activeType
      }
      return {
        text: disabledText,
        type: disabledType
      }
    },
    curApi() {
      let {
        api,
        postData,
        method,
        type,
        isBatchApi,
        isDeleteApi,
        data,
        statusProp,
        changedValue
      } = this
      if (typeof api === 'function') return api
      let params = isDeleteApi ? {} : Object.assign({ [statusProp]: changedValue },this.expandParmas)
      let batchParams = isBatchApi ? { idList: [data.id] } : { id: data.id }
      let option
      if (method === 'post') {
        option = {
          url: urls[type],
          method,
          data: postData ? postData : {
            ...batchParams,
            ...params
          }
        }
      } else {
        option = {
          url: urls[type] + data.id,
          method,
          data: postData ? postData : params
        }
      }
      return () => {
        return request(
          Object.assign(
            option,
            {
              headers: getXMenuType('btn')
            }
          )
        )
      }
    }
  },
  methods: {
    async handleDisabled() {
      let res = await awaitResolve(this.curApi())
      if (res) {
        this.$message.success(`${this.disabledBtnStatus.text}成功`)
        this.handleSuccess()
      }
      if (typeof this.afterSubmit === 'function') await this.afterSubmit(res)
    },
    handleSuccess() {
      this.$emit('success')
      if (typeof this.success === 'function') {
        if (this.success()) return
      }
      this.$set(this.data, this.statusProp, this.changedValue)
    }
  }
};
</script>
